import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Modules from "../components/Modules";
import Moment from "react-moment";

export default function Entry_withoutHero({ data }) {
  // const blogLanding = data.craft.blogLanding[0];
  const entry = data.craft.post[0];
  const modules = data.craft.post[0].modules;
  return (
    <Layout logoColor="dark">
      <div className="no-hero-spacer"></div>
      <SEO>
        {parse(data.craft.seomatic.metaTitleContainer)}
        {parse(data.craft.seomatic.metaTagContainer)}
        {parse(data.craft.seomatic.metaLinkContainer)}
        {parse(JSON.parse(data.craft.seomatic.metaScriptContainer).script)}
        {parse(data.craft.seomatic.metaJsonLdContainer)}
      </SEO>

      <div className="title-lead title-lead--blog">
        <div
          className="container container--1132px mb0"
          data-color={entry.blogCardColor}
        >
          <h1 className="mb0">{entry.title}</h1>

          <footer>
            <Moment format="MMM DD, YYYY">{entry.postDate}</Moment>
            {entry.author && (
              <div className="author-info">
                {entry.author.photo &&
                  <img src={entry.author.photo.url} alt={`${entry.author.name} profile picture`} />
                }
                <b>{entry.author.name}</b>
              </div>
            )}
          </footer>
        </div>
      </div>

      <div
        className="container blog-detail-body container--752px"
        dangerouslySetInnerHTML={{ __html: entry.blogContent }}
      ></div>

      {modules && <Modules modules={modules} />}
    </Layout>
  );
}

export const query = graphql`
  query($id: [Craft_QueryArgument], $uri: String) {
    craft {
      post: entries(id: $id) {
        id
        postDate
        author {
          name
          photo {
            ... on Craft_uploads_Asset {
              id
              url: url(width: 55, position: "centerCenter")
              url_2x: url(width: 110, position: "centerCenter")
              altText
            }
          }
        }
        title
        ... on Craft_blog_blog_Entry {
          blogCardColor
          blogShortText
          blogContent
          modules {
            ...textBlockData
            ...imageGalleryData
            ...fullwidthImageData
            ...imageGridData
            ...teamMemberGridData
            ...blogCardGridData
            ...ctaCardData
            ...imageSliderData
            ...logoGridData
            ...workCardData
            ...rollupCtaData
            ...selectableWorkCardsData
            ...imageWithContentData
            ...videoData
            ...twoColumnTextBlockData
          }
        }
      }

      blogLanding: entries(section: "pages", slug: "blog") {
        id
        uri
        title
      }

      seomatic(uri: $uri) {
        metaTitleContainer
        metaTagContainer
        metaLinkContainer
        metaScriptContainer
        metaJsonLdContainer
      }
    }
  }
`;
